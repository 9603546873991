<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <span class="brand-logo">
        <b-img :src="appLogoImage" alt="logo" class="img_logo"/>
      </span>
    </b-link>
    

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <!-- <b-row> -->
          <b-col> 
            <h1 class="mb-1">
              Expired Token
            </h1>
            <p class="mb-2">
              Oops! Token Has Been Expired.
            </p>
          <div class="text-xs-center">
            <div class="g-recaptcha">
              <vue-recaptcha
                ref="invisibleRecaptcha"
                @verify="onVerify"
                @expired="onExpired"
                :sitekey="siteKey"
                :loadRecaptchaScript="true"
              >
              </vue-recaptcha>
            </div>
          </div>
            <b-button
              variant="primary"
              class="mb-2 btn-sm-block"
              @click="Refresh()"
            >
              Refresh
            </b-button>
            <b-img
              fluid
              :src="imgUrl"
              alt="Error page"
            /> 
          </b-col>
        <!-- </b-row> -->
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { $themeConfig } from "@themeConfig";
import interfaces from "@/templates/_interfaces.js";
import VueRecaptcha from "vue-recaptcha";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    VueRecaptcha,
  },
  setup() {
      // App Name
      const { appName, appLogoImage } = $themeConfig.app;
      return {
        appName,
        appLogoImage,
      };
    },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      registerForm: interfaces.register,
      urlcodeparam: null,
      siteKey: '6LcBoC4jAAAAAOiSKf0hR3G9RVowFtQw0EZOXUhG',
      isLoading: false,
    }
  },
  methods: {
      init: function () {
        this.urlcodeparam = this.$route.params.urlcodeparam;
      },

      onVerify: function (response) {
        this.registerForm.captcha = response;
      },
      onExpired: function () {
        alert("Your verification expired, please fill all information again");
        this.registerForm.captcha       = null;
      },
      resetRecaptcha() {
        this.$refs.recaptcha.reset(); // Direct call reset method
      },
      Refresh: function () {
        if(this.registerForm.captcha == null){
          alert("Invalid Captcha!");
          this.registerForm.captcha  = null;
        }else{
          window.location.href=`https://self-registration.netciti.co.id/#/verifying/${this.urlcodeparam}`;
        }
      },
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  mounted() {
      this.init();
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.mb-2{
  font-size: 15px;
}
.img_logo{
  margin-top: -40px;
  margin-left: -40px;
}
.w-100{
  padding-top: 113px
}

// .img_content{
//   height: 274px;
// }
// .misc-wrapper{
//   min-height: 100vh;
// }
.text-xs-center {
        text-align: center;
    }

    .g-recaptcha {
        display: inline-block;
    }
</style>
